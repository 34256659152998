<template>
    <div>
        <img v-lazy="$store.getters.getUrl('yb_brand')" alt="" />
        <img v-lazy="$store.getters.getUrl('yb_xian')" alt="" />
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
img {
    width: 100%;
    margin-bottom: 15px;
  }
</style>