<template>
  <div class="content">
    <Header></Header>
    <Banner :bannerImg="$store.getters.getUrl('yc_banner')"></Banner>
    <LogoNameImg :logoNameImg="$store.getters.getUrl('yc_logoNameImg')"></LogoNameImg>
    <Downbtn></Downbtn>
    <Xianlu></Xianlu>
    <KindList></KindList>
    <HotList :popularImg="$store.getters.getUrl('yc_popularImg')" :iconUrl="$store.getters.getUrl('yc_popularTitle')"></HotList>
    <Bottom :bottomImg="$store.getters.getUrl('yc_bottomImg')"></Bottom>
  </div>
</template>

<script>
import Banner from "@page/landing/components/banner.vue";
import LogoNameImg from "@page/landing/components/logoNameImg.vue";
import Bottom from "@page/landing/components/bottom.vue";
import HotList from "@page/landing/components/hotList.vue";


import Header from "@page/landing/template/yc/components/header.vue";
import Downbtn from "@page/landing/template/yc/components/downbtn.vue";
import Xianlu from "@page/landing/template/yc/components/xianlu.vue";
import KindList from "@page/landing/template/yc/components/kindList.vue";
// import Serves from "@page/landing/template/yc/components/serves.vue";

export default {
  components: {
    Header,
    Banner,
    LogoNameImg,
    Downbtn,
    Xianlu,
    KindList,
    HotList,
    Bottom
  },
};
</script>

<style lang="scss" scoped>
.content{
  background: url('../../../../assets/base64Img/yc.jpg') center center;
  background-size: 100% 100%;
}
</style>
