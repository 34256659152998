<template>
  <div class="container">
      <img v-lazy="$store.getters.getUrl('yb_logo')" alt="">
  </div>
</template>


<script>
export default {
  data() {
    return {
    };
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  text-align: center;
  padding: 20px 0;
  img{
      width: 270px;
  }
}
</style>