<template>
    <div class="content">
        <div class="banner">
            <img :src="$store.getters.getUrl('rw_banner')" alt="" />
        </div>
        <div class="introduce">
            <img :src="$store.getters.getUrl('rw_int')" alt="" />
        </div>
        <div class="downBox">
            <img @click="downloadApp" :src="$store.getters.getUrl('rw_downBtn')" alt="" />
        </div>
        <div class="lines">
            <img src="../../../../assets/base64Img/0.png" alt="" class="lines-topBg" />
            <img src="../../../../assets/base64Img/2.png" alt="" class="lines-bottomBg" />
            <div class="linesBar">
                <div class="linesTitle">
                    <img :src="$store.getters.getUrl('rw_linestit')" alt="" class="linestit" />
                    <p>ms数字越小速度越快</p>
                    <a :href="kefu_address" class="online">
                        <img :src="$store.getters.getUrl('rw_online')" alt="" />
                    </a>
                </div>
                <ul>
                    <li v-for="(item, index) in list" :key="index">
                        <div class="linesLeft">
                            <span><i class="num1">{{ random(1, 100) }}</i>ms</span>
                            <p class="ellipsistext">{{ clipHttp(item) }}</p>
                        </div>
                        <img @click="openShareUrl(item)" class="enterImg" :src="$store.getters.getUrl('rw_enter')"
                            alt="" />
                    </li>
                </ul>
            </div>
        </div>
        <img :src="$store.getters.getUrl('rw_title1')" class="course" alt="" />
        <img :src="$store.getters.getUrl('rw_game')" class="course" alt="" />
        <img v-if="$store.getters.getUrl('rw_course')" :src="$store.getters.getUrl('rw_course')" class="course" alt="" />
        <a :href="kefu_address" class="kf">
            <img :src="$store.getters.getUrl('rw_kf')" /></a>
        <div class="footer">
            {{ siteName }} {{bottomSite || 'Copyright'}} © 2014-{{ new Date().getFullYear() }}
        </div>
        <!-- 站位 -->
        <div :style="$store.getters.getUrl('rw_footer')&&'height:2rem'"></div>
        <div @click="downloadApp" class="fixedFooter">
            <img :src="$store.getters.getUrl('rw_footer')" class="course" alt="" />
            <img class="btn" :src="$store.getters.getUrl('rw_downBtn')" alt="" />
        </div>
    </div>
</template>
<script>
import { get } from "lodash-es";
import getWebUrlheartbeatsMixins from "@c/mixins/getWebUrlheartbeats.mixins";
import appInstallSdkMixins from "@c/mixins/appInstallSdk.mixins";
export default {
    mixins: [getWebUrlheartbeatsMixins, appInstallSdkMixins],
    computed: {
        siteName() {
            return get(this.$store.state, "appConfig.site_config.configValue.name", "");
        },
        bottomSite() {
            return get(this.$store.state, "appConfig.landing_config.configValue.bottomSite", "");
        },
        app_address() {
            return get(this.$store.state, "webUrls.app_address", "");
        },
        kefu_address() {
            return get(this.$store.state, "webUrls.official_kefu_url", "");
        },
    },
    methods: {
        clipHttp(str) {
            return str.split("://")[1];
        },
        downloadApp() {
            this.wakeupOrInstall(this.$store.getters.appShareSdkPlatform);
            // window.open(this.app_address);
        },
    },
    created() {
        this.addShareNameToUrl()
        this.initInstallSdkJs(this.$store.getters.appkey,this.$store.getters.appShareSdkPlatform);
    },
};
</script>
<style lang="scss" scoped>
.fixedFooter {
    position: fixed;
    bottom: 0;
    left: 50%;
    font-size: 0;
    z-index: 9999;
    width:750px;
    margin-left: -375px;
    background-color: black;
    img{
        display: block;
    }
    .btn {
        position: absolute;
        right: 0.3rem;
        top: 0.8rem;
        width: 2.5rem;
        animation: myfirst 1s infinite linear;
    }
}

.content {
    .kf {
        width: 128px;
        position: fixed;
        right: 3px;
        top: 50%;
        z-index: 999;

        img {
            width: 100%;
            display: block;
        }
    }

    div {
        box-sizing: border-box;
    }

    background: #000000;

    .banner {
        img {
            width: 100%;
            margin: 0;
            padding: 0;
            display: block;
        }
    }

    .introduce {
        margin-top: -6px;
        width: 100%;
        background: url("../../../../assets/base64Img/rw.jpg") center center;
        background-size: 100% auto;
        height: 364px;
        text-align: center;
        padding-top: 40px;
        box-sizing: border-box;

        img {
            width: auto;
            height: 166px;
        }
    }

    .downBox {
        background: url("../../../../assets/base64Img/rw01.jpg") center center;
        background-size: 100% auto;
        width: 100%;
        height: 248px;
        text-align: center;

        img {
            width: 460px;
            height: 120px;
            animation: myfirst 1s infinite linear;
        }
    }

    .lines {
        width: 100%;
        background: url("../../../../assets/base64Img/1.png") center center;
        background-size: 100% auto;
        min-height: 436px;
        position: relative;

        .lines-topBg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 1;
        }

        .lines-bottomBg {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 1;
        }

        .linesBar {
            position: relative;
            z-index: 10;
            padding: 0 0px 100px 0;

            ul {
                width: 560px;
                margin: 0 auto;

                li {
                    margin-top: 20px;
                    display: flex;
                    justify-content: space-between;

                    .linesLeft {
                        display: flex;
                        width: 360px;
                        height: 60px;
                        background: #ffffff;
                        border-radius: 30px;
                        overflow: hidden;

                        span {
                            text-align: center;
                            background-image: linear-gradient(to bottom,
                                    #f4dbb0,
                                    #fdc67e);
                            width: 108px;
                            font-size: 0.32rem;
                            color: #290d02;
                            line-height: 56px;
                        }

                        p {
                            font-size: 0.32rem;
                            text-align: center;
                            line-height: 56px;
                            width: calc(100% - 108px);
                            border: 1px solid #755f38;
                            background: #333333;
                            color: #fffade;
                            box-sizing: border-box;
                            padding: 0 4px;
                            font-weight: bold;
                        }
                    }

                    .enterImg {
                        width: 170px;
                        height: 60px;
                    }
                }
            }
        }

        .linesTitle {
            margin-top: 0;
            overflow: hidden;
            margin: 0 auto;
            // border-top: 1px solid #3f3429;
            width: 560px;
            padding-top: 10px;

            .linestit {
                width: 102px;
                line-height: 24px;
            }

            p {
                display: inline-block;
                color: #fff9df;
                font-size: 22px;
                margin-left: 20px;
            }

            .online {
                float: right;

                img {
                    width: 150px;
                    height: 36px;
                    margin-top: 14px;
                }
            }
        }
    }

    .course {
        width: 100%;
    }
}

.footer {
    color: #ffffff;
    font-size: 0.24rem;
    line-height: 1.22rem;
    text-align: center;
}

@keyframes myfirst {
    from {
        transform: scale(1.1);
    }

    50% {
        transform: scale(1);
    }

    to {
        transform: scale(1.1);
    }
}

.ellipsistext {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
