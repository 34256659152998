import Vue from 'vue';
import App from './App.vue';
import store from '../../store'
import "@assets/css/reset.scss";
import "@assets/css/common.scss";

import VueLazyload from 'vue-lazyload'

import "@assets/js/rem.js";


Vue.use(VueLazyload)

Vue.config.productionTip = false;

new Vue({
  store,
  render: (h) => h(App),
}).$mount('#app');
