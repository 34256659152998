<template>
  <div class="content">
    <Header></Header>
    <Banner :bannerImg="$store.getters.getUrl('ky_banner')" style="background: rgba(0, 0, 0, 0);;"></Banner>
    <img :src="$store.getters.getUrl('ky_intr')" class="intr">
    <Downbtn></Downbtn>
    <img :src="$store.getters.getUrl('ky_labs')" class="labs">
    <Xianlu></Xianlu>
    <div class="title">
      <img :src="$store.getters.getUrl('ky_title')" alt="">
    </div>
    <KindList></KindList>
    <div class="title">
      <img :src="$store.getters.getUrl('ky_hot')" alt="">
    </div>
    <div class="title">
      <img :src="$store.getters.getUrl('ky_qian')" alt="">
    </div>
    <!-- <Serves></Serves> -->
    <a :href="app_address_fixed">
      <div class="fix-down">
        <img :src="$store.getters.getUrl('ky_bottomImg')" alt="">
        <button></button>
      </div>
    </a>
    <div class="fix-service">
      <a :href="kefu_address"><img :src="$store.getters.getUrl('ky_kf')" alt=""></a>
    </div>
  </div>
</template>

<script>
import Banner from "@page/landing/components/banner.vue";
import Header from "@page/landing/template/ky/components/header.vue";
import Downbtn from "@page/landing/template/ky/components/downbtn.vue";
import Xianlu from "@page/landing/template/ky/components/xianlu.vue";
import KindList from "@page/landing/template/ky/components/kindList.vue";
import { get } from "lodash-es";

export default {
  components: {
    Header,
    Banner,
    Downbtn,
    Xianlu,
    KindList,
  },
  computed: {
    app_address_fixed() {
      return get(this.$store.state, "webUrls.app_address_fixed", "");
    },
    kefu_address() {
      return get(this.$store.state, "webUrls.official_kefu_url", "");
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  background: url('../../../../assets/base64Img/ky.jpg') top center no-repeat #dde9f5;
  background-size: 100% auto;

  .fix-service {
    position: fixed;
    right: -50%;
    margin-right: 375px;
    top: 50%;
    margin-top: -96px;
    z-index: 88;

    img {
      width: 124px;
    }
  }

  .intr {
    width: 100%;
    background: rgba(0, 0, 0, 0)
  }

  .labs {
    width: 100%;
    background: rgba(0, 0, 0, 0);
    margin-top: 40px;
  }

  .fix-down {
    width: 750px;
    position: fixed;
    left: 50%;
    margin-left: -375px;
    z-index: 99;
    bottom: 0;

    img {
      width: 100%;
    }

    button {
      width: 220px;
      height: 76px;
      animation: myfirst 1s infinite linear;
      position: absolute;
      right: 20px;
      bottom: 24px;
      background: url('../../../../assets/base64Img/xiazai.png') center no-repeat;
      background-size: 220px 76px;
      border: none;
      outline: none;
      background-color: transparent;
    }
  }

  .title {
    width: 100%;

    img {
      width: 100%;
    }
  }

  @keyframes myfirst {
    from {
      transform: scale(1.1);
    }

    50% {
      transform: scale(1);
    }

    to {
      transform: scale(1.1);
    }
  }
}
</style>
