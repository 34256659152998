<template>
  <div class="footer" :class="list.length > 2 ? '' : 'bottom'">
    <div class="foot-text" bis_skin_checked="1">
      <p>细节精雕 彰显与众不同 {{name}}版权所有。</p>
    </div>
  </div>
</template>
<script>
import getWebUrlheartbeatsMixins from "@c/mixins/getWebUrlheartbeats.mixins";
export default {
  mixins: [getWebUrlheartbeatsMixins],
  computed: {
    name() {
      return (this.$store.state.appConfig.site_config || {}).configValue.name||'盈发科技';
    },
  }
};
</script>
<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 72px;
  background-color: #ced1db;
  position: absolute;
}
.bottom {
  bottom: 0;
}
.foot-text {
  font-size: 26px;
  line-height: 72px;
  text-align: center;
  color: #44474c;
}
</style>