<template>
  <div class="containers">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div v-for="(item,index) in swiperImg_list" :key="index" class="swiper-slide">
          <img :src="url + item" class="swiperImg" />
        </div>
      </div>
    </div>
    <div class="swiper-pagination"></div>
  </div>
</template>


<script>
import Swiper from "swiper";
import kindlistMixins from "@c/mixins/kindlist.mixins";
import "swiper/css/swiper.min.css";
export default {
  name: "KindList",
  mixins: [kindlistMixins],
  mounted() {
    new Swiper(".swiper-container", {
      spaceBetween: 7,
      centeredSlides: true,
      autoplay: {
        disableOnInteraction: false,
        delay: 3000,
      },
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
  },
};
</script>

<style lang="scss">
.containers {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 370px;
  margin: 0;
  .swiper-container {
    width: 100%;
    height: 100%;
  }

  .swiper-pagination {
    width: 100%;
    bottom: 0px;
    text-align: center;
    position: absolute;
    bottom: 30px;
    z-index: 1;
    .swiper-pagination-bullet {
      background: #96addf;
      opacity: 1;
      width: 15px;
      height: 15px;
      margin-right: 10px;
    }
    .swiper-pagination-bullet-active {
      background: #600000;
    }
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    // transition: 300ms;
    // transform: scale(0.5);
  }
  .swiperImg {
    height: 470px;
    border-radius: 16px;
    border:1px solid #ffcc66
  }
  // .swiper-slide-active,
  // .swiper-slide-duplicate-active {
  //   transform: scale(0.7);
  // }
}
</style>