<template>
  <div class="bottom">
    <img v-lazy="bottomImg" alt="" @click="openDown()" />
  </div>
</template>

<script>
import { get } from "lodash-es";
export default {
  name: "bottom",
  props: {
    bottomImg:{
      require: true
    }
  },
  methods: {
    openDown() {
      const app_address_fixed = get(this.$store.state, "webUrls.app_address_fixed", "");
      window.open(app_address_fixed)
    }
  }
};
</script>

<style lang="scss" scoped>
.bottom {
  margin-top: 20px;
  width: 100%;
  img {
    width: 100%;
  }
}
</style>