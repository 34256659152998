<template>
    <div class="gameslist">
      <img class="icon" v-lazy="$store.getters.getUrl('ks_style')" />
      <div class="list">
        <img class="img" :src="$store.getters.getUrl('ks_style1')" alt="" />
        <img class="img" :src="$store.getters.getUrl('ks_style2')" alt="" />
        <img class="img" :src="$store.getters.getUrl('ks_style3')" alt="" />
        <img class="img" :src="$store.getters.getUrl('ks_style4')" alt="" />
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "foot",
    data() {
      return {};
    },
    created() {},
  };
  </script>
  
  <style lang="scss" scoped>
  .gameslist {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    .icon {
      width: 100%;
      margin-top: 25px;
    }
    .list {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 40px;
      .img {
        width: 350px;
        margin-bottom: 10px;
      }
      .rightImg {
        width: 350px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        img {
          width: 170px;
        }
      }
    }
  }
  </style>
  