<template>
  <div class="xianlu">
    <img :src="$store.getters.getUrl('cp_xianlu')" alt="" />
    <ul>
      <li v-for="(item, index) in list" :key="index">
        <div class="linesLeft">
          <span v-html="random(1, 100) + 'ms'"></span>
          <p>{{ item }}</p>
        </div>
        <button class="enter" @click="open(item)">立即进入</button>
      </li>
    </ul>
  </div>
</template>

<script>
import getWebUrlheartbeatsMixins from "@c/mixins/getWebUrlheartbeats.mixins";
export default {
  mixins: [getWebUrlheartbeatsMixins],
  name: "xianlu",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.xianlu {
  width: 100%;
  box-sizing: border-box;
  font-size: 25px;
  img {
    width: 100%;
  }
  ul {
    width: 716px;
    margin: 0 auto;
    li {
      margin-top: 0.2rem;
      overflow: hidden;
      .linesLeft {
        border: 1px solid #dedede;
        float: left;
        overflow: hidden;
        width: 530px;
        height: 60px;
        border-radius: 9px;
        span {
          float: left;
          display: block;
          width: 128px;
          text-align: center;
          color: #ffffff;
          background: #61a2d7;
          border-radius: 9px;
          line-height: 60px;
        }
        p {
          float: left;
          width: calc(100% - 128px);
          text-align: center;
          line-height: 60px;
          color: #666666;
          font-size: 25px;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        }
      }
      .enter {
        float: right;
        text-decoration: none;
        border-radius: 4px;
        background: #db443c;
        color: #ffffff;
        text-align: center;
        line-height: 60px;
        width: 162px;
        height: 60px;
        display: block;
        font-size: 25px;
        border: none;
        cursor: pointer;
      }
      .disabledBtn {
        background: #c0c4cc;
      }
    }
  }
}
</style>
