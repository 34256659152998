<template>
  <div class="content">
    <Banner :bannerImg="$store.getters.getUrl('wn_banner')"></Banner>
    <DownBtn></DownBtn>
    <Xianlu></Xianlu>
    <KindList></KindList>
    <Introduce></Introduce>
    <Bottom :bottomImg="$store.getters.getUrl('wn_bottomImg')"></Bottom>
  </div>
</template>

<script>
import Banner from "@page/landing/components/banner.vue";
import Bottom from "@page/landing/components/bottom.vue";


import DownBtn from "@page/landing/template/wn/components/downbtn.vue";
import Xianlu from "@page/landing/template/wn/components/xianlu.vue";
import KindList from "@page/landing/template/wn/components/kindList.vue";
import Introduce from "@page/landing/template/wn/components/introduce.vue";



export default {
  components: {
    Banner,
    DownBtn,
    KindList,
    Xianlu,
    Introduce,
    Bottom
  },
};
</script>

<style lang="scss" scoped>
.content{
  background: url('../../../../assets/base64Img/wn.jpg') center center;
  background-size: 100% 100%;
}
</style>
