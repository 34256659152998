<template>
  <div>
    <Header></Header>
    <Banner :bannerImg="$store.getters.getUrl('cp_banner')"></Banner>
    <LogoNameImg :logoNameImg="$store.getters.getUrl('cp_logoNameImg')"></LogoNameImg>
    <DownBtn></DownBtn>
    <DownBottomImg></DownBottomImg>
    <Xianlu></Xianlu>
    <KindList></KindList>
    <HotList :popularImg="$store.getters.getUrl('cp_popularImg')" :iconUrl="$store.getters.getUrl('cp_rmen')"></HotList>
    <!-- <Footer></Footer> -->
    
  </div>
</template>

<script>
import { get } from "lodash-es";
import Banner from "@page/landing/components/banner.vue";
import LogoNameImg from "@page/landing/components/logoNameImg.vue";
import HotList from "@page/landing/components/hotList.vue";


import Header from "@page/landing/template/cp/components/header.vue";
import DownBtn from "@page/landing/template/cp/components/downBtn.vue";
import Xianlu from "@page/landing/template/cp/components/xianlu.vue";
// import Footer from "@page/landing/template/cp/components/footer.vue";
import KindList from "@page/landing/template/cp/components/kindList.vue";
import DownBottomImg from "@page/landing/template/cp/components/downBottomImg.vue";

export default {
  components: {
    Header,
    Banner,
    LogoNameImg,
    DownBtn,
    KindList,
    HotList,
    DownBottomImg,
    Xianlu,
    // Footer,
  },
  computed: {
        kefu_address() {
            return get(this.$store.state, "webUrls.official_kefu_url", "");
        },
    },
};
</script>

<style lang="scss">
.kefu{
  position: fixed;
  right: 0;
  top:54%;
  width: 100px;
  z-index: 99;
}
</style>
