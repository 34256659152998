<template>
  <div class="container">
    <div class="lpj">
      <img
        :src="$store.getters.getUrl('pj01_mian_lpj')"
        alt=""
        class="lpj_img"
      />
    </div>
    <div class="flex">
      <a :href="app_address_fixed" class="btn flex" target="_blank" >
      <img
        :src="$store.getters.getUrl('pj01_mian_download')"
        alt=""
        class="download"
      />
      </a>
    </div>
    <div class="address">
      <ul>
        <li v-for="(item, index) in list" :key="index">
          <div class="linesLeft">
            <span class="site" v-html="random(1, 100) + 'ms'"></span>
            <p>{{ setSubString(item) }}</p>
          </div>
          <div class="btn" @click="open(item)">立即进入</div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import getWebUrlheartbeatsMixins from "@c/mixins/getWebUrlheartbeats.mixins";
import { get } from "lodash-es";
export default {
  mixins: [getWebUrlheartbeatsMixins],
  name: "xianlu",
  data() {
    return {};
  },
  computed: {
    app_address_fixed() {
      return get(this.$store.state, "webUrls.app_address_fixed", "");
    },
  },
  methods: {
    setSubString(value) {
      if (value.length > 20) {
        let str = value.substring(0, 20) + "......";
        return str;
      }
      return value;
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  .lpj {
    display: flex;
    justify-content: center;
    padding: 10px 0 30px;
  }
  .lpj_img {
    width: 100%;
    height: 180px;
  }
  .flex {
    display: flex;
    justify-content: center;
  }
  .download {
    width: 70%;
    height: 140px;
    animation: myfirst 1s infinite linear;
  }
  ul {
    width: 100%;
    padding-bottom: 10px;
    margin-top: 40px;
    li {
      display: flex;
      margin-bottom: 20px;
      .linesLeft {
        width: 100%;
        display: flex;
        text-align: center;
        border: 1px solid #ffcc66;
        line-height: 60px;
        .site {
          width: 120px;
          font-size: 28px;
          font-weight: 600;
          color: #fff;
          background: #b0341e;
          border-right: 1px solid #ffcc66;
          display: inline-block;
        }
        p {
          flex: 1;
          font-size: 32px;
          color: #ffcc66;
        }
      }
      .btn {
        font-size: 28px;
        color: #fff;
        width: 160px;
        line-height: 60px;
        text-align: center;
        margin-left: 16px;
        background: -webkit-linear-gradient(right, #ad2e1d, #bd5321);
        border-radius: 10px;
      }
    }
  }
  @keyframes myfirst {
    from {
      transform: scale(1.1);
    }
    50% {
      transform: scale(1);
    }
    to {
      transform: scale(1.1);
    }
  }
}
</style>
