<template>
  <div class="btns">
    <a :href="app_address_fixed" class="btn" target="_blank">
      <img v-lazy="$store.getters.getUrl('ky_btn')" alt="" />
    </a>
  </div>
</template>

<script>
import { get } from "lodash-es";
export default {
  name: "downBtn",
  data() {
    return {};
  },
  computed: {
    app_address_fixed() {
      return get(this.$store.state, "webUrls.app_address_fixed", "");
    },
  },
};
</script>

<style lang="scss" scoped>
.btns {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 25px;
  height: 60px;

  .btn {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    animation: myfirst 1s infinite linear;

    img {
      width: 380px;
      height: 120px !important;
      display: inline-block;
    }
  }
}

@keyframes myfirst {
  from {
    transform: scale(1.1);
  }

  50% {
    transform: scale(1);
  }

  to {
    transform: scale(1.1);
  }
}
</style>