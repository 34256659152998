<template>
  <!-- <TopIconContainer >
    <img v-lazy="$store.getters.getUrl('Gold_popularImg')" />
  </TopIconContainer> -->
  <div class="container">
    <img class="title" v-lazy="$store.getters.getUrl('ob_fuwuTitle')" alt="">
    <div class="list">
        <img v-for="(item, idx) in list" :key="idx" v-lazy="$store.getters.getUrl(item)" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "Serves",
  created() {},
  computed: {
      list(){
          return ['ob_fuwuImg1', 'ob_fuwuImg2', 'ob_fuwuImg3', 'ob_fuwuImg4']
      }
  }
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  margin-bottom: 50px;
  .title {
    width: 100%;
    margin-top: 25px;
  }
  .list {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 30px;
      img {
          width: 350px;
      }
  }
}
</style>
