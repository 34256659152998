<template>
  <TopIconContainer :iconUrl="$store.getters.getUrl('la01_swiperTitle')">
    <div class="containers">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div v-for="item in swiperImg_list" :key="item" class="swiper-slide">
            <img :src="url + item" />
            <!-- <img v-else v-lazy="$store.getters.getUrl(item)" /> -->
          </div>
        </div>
        <!-- Add Pagination -->
        <!-- <div class="swiper-pagination"></div> -->
      </div>
    </div>
  </TopIconContainer>
</template>


<script>
import TopIconContainer from "@page/landing/components/topIconContainer.vue";
import Swiper from "swiper";
import kindlistMixins from "@c/mixins/kindlist.mixins";
import "swiper/css/swiper.min.css";
export default {
  name: "KindList",
  mixins:[kindlistMixins],
  components: {
    TopIconContainer,
  },
  mounted() {
    new Swiper(".swiper-container", {
      slidesPerView: 3,
      spaceBetween: 10,
      centeredSlides: true,
      autoplay: {
        disableOnInteraction: false,
        delay: 3000,
    },
      loop: true,
      autoplayDisableOnInteraction : false,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
  },
};
</script>

<style lang="scss" scoped>
.containers {
  overflow: hidden;
  width: 100%;
  height: 500px;
  margin-top: 20px;
  margin-bottom: 25px;
  .swiper-container {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    transition: 300ms;
    transform: scale(0.9);
  }
  img {
    height: 450px;
  }
  .swiper-slide-active,
  .swiper-slide-duplicate-active {
    transform: scale(1.1);
  }
}
</style>