<template>
  <div class="gameslist">
    <img class="icon" v-lazy="$store.getters.getUrl('ks_game')" />
    <div class="list">
      <img class="img" :src="$store.getters.getUrl('ks_gameImg1')" alt="" />
      <div class="rightImg">
        <img :src="$store.getters.getUrl('ks_gameImg2')" alt="" />
        <img :src="$store.getters.getUrl('ks_gameImg3')" alt="" />
      </div>
      <img class="img" :src="$store.getters.getUrl('ks_gameImg4')" alt="" />
      <img class="img" :src="$store.getters.getUrl('ks_gameImg5')" alt="" />
      <img class="img" :src="$store.getters.getUrl('ks_gameImg6')" alt="" />
      <img class="img" :src="$store.getters.getUrl('ks_gameImg7')" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "games",
  data() {
    return {};
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.gameslist {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  .icon {
    width: 100%;
    margin-top: 25px;
  }
  .list {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 40px;
    .img {
      width: 350px;
      height: 195px;
      margin-bottom: 10px;
    }
    .rightImg {
      width: 350px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      img {
        width: 170px;
      }
    }
  }
}
</style>
