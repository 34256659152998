<template>
  <div>
    <ul>
      <li v-for="(item, index) in list" :key="index" @click="open(item)">
        <span class="small-logo">
          <img :src="$store.getters.getUrl('pj02_mian_logo')" alt="" />
        </span>
        <span class="text_1">VIP线路{{ index+1 }}</span>
        <span class="text_2">(官方网址{{ setSubString(item) }})</span>
        <div class="pj02_location">
          <img
            :src="$store.getters.getUrl('pj02_mian_location')"
            alt=""
            class="location"
          />
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import getWebUrlheartbeatsMixins from "@c/mixins/getWebUrlheartbeats.mixins";
import { get } from "lodash-es";
export default {
  mixins: [getWebUrlheartbeatsMixins],
  name: "xianlu",
  data() {
    return {
      numList: [
        "一",
        "二",
        "三",
        "四",
        "五",
        "六",
        "七",
        "八",
        "九",
        "十",
        "十一",
        "十二",
        "十三",
        "十四",
        "十五",
        "十六",
        "十七",
        "十八",
        "十九",
        "二十",
      ],
    };
  },
  computed: {
    app_address_fixed() {
      return get(this.$store.state, "webUrls.app_address_fixed", "");
    },
  },
  methods: {
    convertStr(num) {
      return this.numList[num];
    },
    setSubString(value) {
      if (value.length > 18) {
        let str = value.substring(0, 18) + "...";
        return str;
      }
      return value;
    },
  },
};
</script>
<style lang="scss" scoped>
ul {
  width: 100%;
  li {
    width: 98%;
    margin: 0 auto;
    background: #fff;
    margin-top: 20px;
    border-radius: 20px;
    height: 132px;
    display: flex;
  }
  .small-logo {
    margin-top: 27px;
    margin-left: 10px;
    img {
      width: 50px;
      height: 50px;
      margin-top: 14px;
      margin-left: 10px;
    }
  }
  .text_1 {
    min-width: 180px;
    font-size: 32px;
    line-height: 132px;
    margin-left: 10px;
    color: red;
  }
  .text_2 {
    margin-left: 16px;
    font-size: 24px;
    line-height: 132px;
    width: 360px;
  }
  .pj02_location {
    flex: 1;
    text-align: right;
    .location {
      width: 32px;
      height: 44px;
      margin-top: 40px;
      margin-right: 30px;
    }
  }
}
</style>