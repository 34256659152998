<template>
  <div class="containe">
    <div class="titleIcon">
      <img class="icon" :src="iconUrl" v-if="iconUrl" />
    </div>
    <div class="content"><slot></slot></div>
  </div>
</template>


<script>
export default {
  props: {
    iconUrl: {
      require: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.containe {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  margin-top: 30px;
  .titleIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .icon {
      width: 100%;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
  }
}
</style>