<template>
  <TopIconContainer :iconUrl="$store.getters.getUrl('yb_swiperTitle')">
    <div class="kindList">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div v-for="item in swiperImg_list" :key="item" class="swiper-slide">
            <img :src="url + item" />
            <!-- <img v-else v-lazy="$store.getters.getUrl(item)" /> -->
          </div>
        </div>
        <!-- Add Pagination -->
      </div>
        <div class="swiper-pagination"></div>
    </div>
  </TopIconContainer>
</template>


<script>
import TopIconContainer from "@page/landing/components/topIconContainer.vue";
import Swiper from "swiper";
import kindlistMixins from "@c/mixins/kindlist.mixins";
import "swiper/css/swiper.min.css";
export default {
  name: "KindList",
  components: {
    TopIconContainer,
  },
  mixins:[kindlistMixins],
  mounted() {
    new Swiper(".swiper-container", {
      slidesPerView: 1,
      spaceBetween: 10,
      centeredSlides: true,
      autoplay: {
        disableOnInteraction: false,
        delay: 3000,
    },
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
  },
};
</script>

<style lang="scss">
.kindList {
  position: relative;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 25px;
  .swiper-container {
    width: 100%;
    height: 100%;
  }

  .swiper-pagination {
    width: 100%;
    bottom: -30px;
    text-align: center;
      .swiper-pagination-bullet{
        border: 1px solid #e1bd9f;
        background: none;
        opacity: 1;
        width: 20px ;
        height: 20px ;
        margin-right: 10px;
      }
      .swiper-pagination-bullet-active{
        background: #c9a987;
      }
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    transition: 300ms;
  }
  img {
    height: 412px;
  }
}
</style>