<script>
import urijs from "urijs";
export default {
  computed: {
    list() {
      if (this.$store.state.webUrls.web_landing_page_domain2) {
        return (
          (this.$store.state.webUrls || {}).web_landing_page_domain2 || {}
        ).split(",");
      } else {
        this.$store.dispatch("getWebUrls");
        return [];
      }
      // return (
      //   (this.$store.state.webUrls || {}).web_landing_page_domain2 || {}
      // ).split(",");
    },
  },
  methods: {
    open(url) {
      const currUrl = urijs(url);
      const protocol = currUrl.protocol();
      if(protocol){
        window.open(url, "_blank”");
      }else{
        window.open(`//${url}`, "_blank”");
      }

    },
    random(min, max) {
      return Math.floor(Math.random() * (max - min)) + min;
    },
  },
};
</script>
