<template>
  <div class="content">
    <Banner :bannerImg="$store.getters.getUrl('yc_banner')"></Banner>
    <Downbtn></Downbtn>
    <Xianlu></Xianlu>
    <Games></Games>
    <!-- <HotList :popularImg="$store.getters.getUrl('ks_remen')" :iconUrl="$store.getters.getUrl('ks_remenTitle')"></HotList>
    <Bottom :bottomImg="$store.getters.getUrl('ks_gif')" :style="{'marginTop':'40px'}"></Bottom> -->
  </div>
</template>

<script>
import Banner from "@page/landing/components/banner.vue";
// import Bottom from "@page/landing/components/bottom.vue";
// import HotList from "@page/landing/components/hotList.vue";


import Downbtn from "@page/landing/template/copyks/components/downbtn.vue";
import Xianlu from "@page/landing/template/copyks/components/xianlu.vue";
import Games from "@page/landing/template/copyks/components/games.vue";

export default {
  components: {
    Banner,
    Downbtn,
    Xianlu,
    Games,
    // HotList,
    // Bottom
  },
};
</script>

<style lang="scss" scoped>
.content{
  background: #000;
}
</style>
