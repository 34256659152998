<template>
  <div class="content">
    <Banner :bannerImg="$store.getters.getUrl('xf_banner')"></Banner>
    <!-- <LogoNameImg :logoNameImg="$store.getters.getUrl('xf_logoNameImg')"></LogoNameImg> -->
    <DownBtn></DownBtn>
    <Xianlu></Xianlu>
    <KindList></KindList>
    <Heroes :heroList="$store.getters.getUrl('xf_heroList')" :iconUrl="$store.getters.getUrl('xf_heroTitle')"></Heroes>
    <HotList :popularImg="$store.getters.getUrl('xf_popularImg')" :iconUrl="$store.getters.getUrl('xf_popularTitle')"></HotList>
    <Bottom :bottomImg="$store.getters.getUrl('xf_bottomImg')"></Bottom>
  </div>
</template>

<script>
import Banner from "@page/landing/components/banner.vue";
// import LogoNameImg from "@page/landing/components/logoNameImg.vue";

import HotList from "@page/landing/components/hotList.vue";

import Heroes from "@page/landing/template/xf/components/heroes.vue";
import DownBtn from "@page/landing/template/xf/components/downbtn.vue";
import Xianlu from "@page/landing/template/xf/components/xianlu.vue";
import KindList from "@page/landing/template/xf/components/kindList.vue";
import Bottom from "@page/landing/template/xf/components/bottom.vue";

export default {
  components: {
    Banner,
    Heroes,
    DownBtn,
    Xianlu,
    KindList,
    HotList,
    Bottom
  },
};
</script>

<style lang="scss" scoped>
.content{
  background: url('../../../../assets/base64Img/xf.jpg') center center;
  background-size: 100% 100%;
}
</style>