<template>
    <div>
        <img :src="$store.getters.getUrl('cp_xian')" alt="" />
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
img {
    width: 100%;
  }
</style>