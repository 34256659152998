<template>
  <a :href="app_address_fixed" class="btn" target="_blank">
    <img v-lazy="$store.getters.getUrl('wn_downBtn')" alt="" />
  </a>
</template>

<script>
import { get } from "lodash-es";
export default {
  name: "downBtn",
  data() {
    return {};
  },
  computed: {
    app_address_fixed() {
      return get(this.$store.state, "webUrls.app_address_fixed", "");
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
  margin-bottom: 25px;
  align-items: center;
  img {
    width: 85%;
    cursor: pointer;
    animation: myfirst 1s infinite linear;
  }
}
@keyframes myfirst {
  from {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}
</style>