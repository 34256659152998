<template>
  <div class="pj_container_footer">
    <div class="flex">
      <img
        :src="$store.getters.getUrl('pj01_mian_pjTitle1')"
        alt=""
        class="lpj_title1"
      />
    </div>
    <div class="footer_img">
      <img
        :src="$store.getters.getUrl('pj01_footer_img')"
        alt=""
        class="lpj_footer_img"
      />
    </div>
  </div>
</template>
<script>
export default {
  methods: {},
};
</script>
<style lang="scss" scoped>
.pj_container_footer {
  width: 100%;
  margin-top: 50px;
  .lpj_title1 {
    width: 70%;
    height: 80px;
  }
  .flex {
    display: flex;
    justify-content: center;
  }
  .lpj_footer_img {
    width: 100%;
  }
}
</style>