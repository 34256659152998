<template>
    <div class="content" :style="`background: url(${$store.getters.getUrl('lsyj_bg')}) no-repeat center top;`">
      <div class="logo">
        <img  :src="$store.getters.getUrl('lsyj_logo')" alt="">
      </div>
      <div class="banner1">
        <img  :src="$store.getters.getUrl('lsyj_banner')" alt="">
      </div>
      
      <ul class="linkbox">
            <li @click="downloadApp"><a><b>APP下载</b><span>
                <img src="@assets/template/lsyj/tag01.png"></span></a>
            </li>
            <li>
                <a :href="kefu_address">
                    <b :style="`background: url(${$store.getters.getUrl('lsyj_a1')}) no-repeat 1rem center;`">在线客服</b>
                </a>
            </li>
        </ul>
        <div class="xianlu">
            <img :src="$store.getters.getUrl('cp_xianlu')" alt="" />
            <ul>
            <li v-for="(item, index) in list" :key="index">
                <div class="linesLeft">
                <span v-html="random(1, 100) + 'ms'"></span>
                <p>{{ item }}</p>
                </div>
                <button class="enter" @click="open(item)">立即进入</button>
            </li>
            </ul>
        </div>
        <!-- <ul class="linkurlbox">
            <li v-for="(item, index) in list" :key="index">
                <p class="num1"><i >{{ random(1, 100) }}</i>ms</p>
                    <p class="ellipsistext">{{ clipHttp(item) }}</p>
                <img @click="openShareUrl(item)" class="enterImg" :src="$store.getters.getUrl('rw_enter')"
                    alt="" />
            </li>
        </ul> -->
    </div>
</template>
<script>
import { get } from "lodash-es";
import getWebUrlheartbeatsMixins from "@c/mixins/getWebUrlheartbeats.mixins";
import appInstallSdkMixins from "@c/mixins/appInstallSdk.mixins";
export default {
    mixins: [getWebUrlheartbeatsMixins, appInstallSdkMixins],
    computed: {
        siteName() {
            return get(this.$store.state, "appConfig.site_config.configValue.name", "");
        },
        bottomSite() {
            return get(this.$store.state, "appConfig.landing_config.configValue.bottomSite", "");
        },
        app_address() {
            return get(this.$store.state, "webUrls.app_address_fixed", "");
        },
        kefu_address() {
            return get(this.$store.state, "webUrls.official_kefu_url", "");
        },
    },
    methods: {
        clipHttp(str) {
            return str.split("://")[1];
        },
        downloadApp() {
            // this.wakeupOrInstall(this.$store.getters.appShareSdkPlatform);
            window.open(this.app_address);
        },
    },
    created() {
        this.addShareNameToUrl()
        this.initInstallSdkJs(this.$store.getters.appkey,this.$store.getters.appShareSdkPlatform);
    },
};
</script>
<style lang="scss" scoped>
.ellipsistext {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.linkurlbox{
    width: 80%;
    margin: 0.6rem auto;
    border: 3px solid #65add3;
    padding: 0.2rem;
    border-radius: 0.2rem;
    li{
        display: flex;
        font-size: 0.4rem;
        justify-content: center;
        align-items: center;
    margin: 0 auto 0.15rem;
    }
    .num1{
            flex:1;
                min-width: 1.8rem;
        }
        .ellipsistext{
            flex:6
        }
    .enterImg{
        width: 2rem;
    }
}
.xianlu {
  width: 100%;
  box-sizing: border-box;
  font-size: 25px;
  padding-bottom: 20px;
  img {
    width: 100%;
  }
  ul {
    width: 716px;
    margin: 0 auto;
    li {
      margin-top: 0.2rem;
      overflow: hidden;
      .linesLeft {
        border: 1px solid #dedede;
        float: left;
        overflow: hidden;
        width: 530px;
        height: 60px;
        border-radius: 9px;
        span {
          float: left;
          display: block;
          width: 128px;
          text-align: center;
          color: #ffffff;
          background: #61a2d7;
          border-radius: 9px;
          line-height: 60px;
        }
        p {
          float: left;
          width: calc(100% - 128px);
          text-align: center;
          line-height: 60px;
          color: #666666;
          font-size: 25px;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        }
      }
      .enter {
        float: right;
        text-decoration: none;
        border-radius: 4px;
        background: #db443c;
        color: #ffffff;
        text-align: center;
        line-height: 60px;
        width: 162px;
        height: 60px;
        display: block;
        font-size: 25px;
        border: none;
        cursor: pointer;
      }
      .disabledBtn {
        background: #c0c4cc;
      }
    }
  }
}
.linkbox{
    padding-top: 0.2rem;
    margin: 0 auto;
    width: 60%;
        li{
            padding-bottom: 0.4rem;
            img {
                width: 100%;
                margin: 0 auto;
                padding-bottom: 30px;
            }
        }
        a{
            font-size: .4rem;
            line-height: 1.4rem;
            display: block;
            position: relative;
            font-weight: bold;
            font-size: 32px;
            color: #3093f0;
            background: url('@assets/template/lsyj/a1.png');
            text-decoration: none;
            background-size: 100% 100%;
        }
        b {
            background: url('@assets/template/lsyj/img01.png') no-repeat 1rem center;
            display: block;
            padding-left: 2rem;
            background-size: 0.58rem 0.54rem !important;
            font-size: 0.56rem;
        }
        span {
            display: block;
            position: absolute;
            top: 0;
            right: 20px;
            width: 23px;
        }
       
 
}
.content{
    position: static;
    
    background-size: cover;
    max-width: 750px;
    margin: 0 auto;
    background-size: cover;
    min-height: 100vh;
    overflow: auto;
    padding-bottom: 1rem;
}
.logo{
    display: flex;
    justify-content: center;
    align-items: center;
    img{
        width: 4rem;
        margin:0.5rem 0
    }
}
.banner1{
    display: flex;
    justify-content: center;
    align-items: center;
    img{
        width: 7rem;
        margin:0.5rem 0
    }
}
</style>
