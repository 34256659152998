<template>
  <div class="container-pj01">
    <div class="flex">
      <img
        :src="$store.getters.getUrl('pj01_mian_pjTitle')"
        alt=""
        class="lpj_title1"
      />
    </div>
    <div class="lpj_game">
      <img
        v-for="(item, idx) in list"
        :key="idx"
        v-lazy="$store.getters.getUrl(item)"
        alt=""
      />
    </div>
  </div>
</template>
<script>
export default {
  methods: {},
  computed: {
    list() {
      return [
        "pj_game1",
        "pj_game2",
        "pj_game3",
        "pj_game4",
        "pj_game5",
        "pj_game6",
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
.container-pj01 {
  width: 100%;
  .lpj_title1 {
    width: 70%;
    height: 80px;
  }
  .flex {
    display: flex;
    justify-content: center;
  }
  .lpj_game {
    margin-top: 40px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    img {
      width: 48%;
      height: 256px;
      margin-bottom: 10px;
    }
  }
}
</style>