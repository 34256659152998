<template>
  <div class="teachPop">
    <img :src="imgUrl" @click="close" alt="" />
  </div>
</template>

<script>
export default {
  props: {
    imgUrl: {
      require: true,
    },
  },
  methods: {
    close() {
      this.$emit("closePop", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.teachPop {
  position: fixed;
  z-index: 3;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  img {
    width: 100%;
    display: block;
  }
}
</style>