<template>
  <div class="introduce">
    <img v-lazy="$store.getters.getUrl('wn_introduceImg_1')" alt="" />
    <img v-lazy="$store.getters.getUrl('wn_introduceImg_2')" alt="" />
    <img v-lazy="$store.getters.getUrl('wn_introduceImg_3')" alt="" />
  </div>
</template>

<script>
export default {
  name: "introduce",
};
</script>

<style lang="scss" scoped>
.introduce {
  width: 100%;
  img {
    width: 100%;
  }
}
</style>