<template>
  <div class="pj02_content" :class="list.length > 2 ? '' : 'vh'">
    <Header></Header>
    <Mian></Mian>
    <p class="wire"></p>
    <Link></Link>
    <Footer></Footer>
  </div>
</template>
<script>
import getWebUrlheartbeatsMixins from "@c/mixins/getWebUrlheartbeats.mixins";
import Header from "@page/landing/template/pj02/components/header.vue";
import Mian from "@page/landing/template/pj02/components/mian.vue";
import Link from "@page/landing/template/pj02/components/link.vue";
import Footer from "@page/landing/template/pj02/components/footer.vue";
export default {
  components: {
    Header,
    Mian,
    Link,
    Footer,
  },
  mixins: [getWebUrlheartbeatsMixins],
};
</script>
<style lang="scss" scoped>
.pj02_content {
  background: #f0f1f5;
}
.vh {
  height: 100vh;
}
.wire {
  border-top: 1px dotted #dcdde2;
  width: 96%;
  margin: auto;
  margin-top: 24px;
  margin-bottom: 8px;
}
</style>