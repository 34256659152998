<template>
  <div class="pj-container">
    <div class="box logo">
      <!-- <img :src="$store.getters.getUrl('ob_header_img1')" alt="" /> -->
      <img :src="$store.getters.getUrl('pj02_logo')" alt="" class="logo" />
    </div>
    <!-- <div class="box" @click="openPop()">
      <img :src="$store.getters.getUrl('pj01_header_course')" alt="" class="course"/>
    </div> -->
    <transition name="fade">
      <TrustTeachPop
        v-if="TrustTeachPopShow"
        @closePop="closePop"
        :imgUrl="teach"
      ></TrustTeachPop>
    </transition>
  </div>
</template>


<script>
import TrustTeachPop from "../../../components/trustTeachPop.vue";
export default {
  data() {
    return {
      TrustTeachPopShow: false,
    };
  },
  components: {
    TrustTeachPop,
  },
  computed: {
    //备份方案，教程配置了就读配置，没配置读默认图片
    teach() {
      return this.$store.getters.getUrl("ob_teach");
    },
  },
  methods: {
    closePop(closePop) {
      this.TrustTeachPopShow = closePop;
      document.body.removeAttribute("style");
    },
    openPop() {
      this.TrustTeachPopShow = true;
      document.querySelector("body").setAttribute("style", "overflow:hidden;");
    },
  },
};
</script>

<style lang="scss" scoped>
.pj-container {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
  background: #fff;
  .logo {
    width: 360px;
    margin: 0 auto;
  }
  .box {
    display: flex;
    align-items: center;
    line-height: 35px;
    cursor: pointer;
    .course{
        // width: 200px;
        height: 34px;
        width: 200px;
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>