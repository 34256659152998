<template>
  <TopIconContainer :iconUrl="$store.getters.getUrl('yc_swiperTitle')">
    <div class="containers">
      <div class="swiper-container" >
        <div class="swiper-wrapper">
          <div v-for="item in swiperImg_list" :key="item" class="swiper-slide">
            <img :src="url + item" class="swiperImg" />
            <!-- <img v-else v-lazy="$store.getters.getUrl(item)" /> -->
          </div>
        </div>
        <!-- Add Pagination -->
      </div>
        <div class="swiper-pagination"></div>
    </div>
  </TopIconContainer>
</template>


<script>
import TopIconContainer from "@page/landing/components/topIconContainer.vue";
import kindlistMixins from "@c/mixins/kindlist.mixins";
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
export default {
  name: "KindList",
  mixins:[kindlistMixins],
  components: {
    TopIconContainer,
  },
  mounted() {
    new Swiper(".swiper-container", {
      slidesPerView: 2,
      centeredSlides: true,
      autoplay: {
        disableOnInteraction: false,
        delay: 3000,
      },
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
  },
};
</script>

<style lang="scss">
.containers {
  width: 100%;
  margin-top: -10px;
  margin-bottom: 25px;
  position: relative;
  overflow: hidden;
  .swiper-container {
    width: 100%;
    height: 100%;
  }
  
  .swiper-pagination {
    width: 100%;
    bottom: 0px;
    text-align: center;
      .swiper-pagination-bullet{
        background: #b4a3d9;
        opacity: 1;
        width: 15px ;
        height: 15px ;
        margin-right: 10px;
      }
      .swiper-pagination-bullet-active{
        background: #7b5fc5;
      }
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    transition: 300ms;
    transform: scale(0.6);
  }
  .swiperImg {
    height: 450px;
  }
  .swiper-slide-active,
  .swiper-slide-duplicate-active {
    transform: scale(0.8);
  }
}
</style>