<template>
  <div class="link">
    <ul>
      <li @click="open(app_address_fixed)">
        <span class="small-appicon">
          <img :src="$store.getters.getUrl('pj02_mian_appicon')" alt="" />
        </span>
        <div class="text">
          <p>苹果系统下载<br /><span>活动不断 彩金不断</span></p>
        </div>
      </li>
      <li @click="open(app_address_fixed)">
        <span class="small-appicon">
          <img :src="$store.getters.getUrl('pj02_mian_appicon')" alt="" />
        </span>
        <div class="text">
          <p>安卓系统下载<br /><span>活动不断 彩金不断</span></p>
        </div>
      </li>
      <li @click="open('https://www.google.cn/intl/zh-CN/chrome/')">
        <span class="small-appicon">
          <img :src="$store.getters.getUrl('pj02_mian_google')" alt="" />
        </span>
        <div class="text">
          <p>浏览器下载<br /><span>安全浏览 预防劫持</span></p>
        </div>
      </li>
      <li @click="open('https://www.ub66.com/')">
        <span class="small-appicon">
          <img :src="$store.getters.getUrl('pj02_mian_browser')" alt="" />
        </span>
        <div class="text">
          <p>浏览器下载<br /><span>安全浏览 预防劫持</span></p>
        </div>
      </li>
      <li @click="open(kefu_address)">
        <span class="small-appicon">
          <img :src="$store.getters.getUrl('pj02_mian_kf')" alt="" />
        </span>
        <div class="text">
          <p>24小時客服<br /><span>7*24在線人工客服</span></p>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import getWebUrlheartbeatsMixins from "@c/mixins/getWebUrlheartbeats.mixins";
import { get } from "lodash-es";
export default {
  mixins: [getWebUrlheartbeatsMixins],
  name: "xianlu",
  data() {
    return {
      numList: [
        "一",
        "二",
        "三",
        "四",
        "五",
        "六",
        "七",
        "八",
        "九",
        "十",
        "十一",
        "十二",
        "十三",
        "十四",
        "十五",
        "十六",
        "十七",
        "十八",
        "十九",
        "二十",
      ],
    };
  },
  computed: {
    app_address() {
      return get(this.$store.state, "webUrls.app_address", "");
    },
    kefu_address() {
      return get(this.$store.state, "webUrls.official_kefu_url", "");
    },
    app_address_fixed() {
      return get(this.$store.state, "webUrls.app_address_fixed", "");
    },
  },
  methods: {
    convertStr(num) {
      return this.numList[num];
    },
    setSubString(value) {
      if (value.length > 18) {
        let str = value.substring(0, 18) + "...";
        return str;
      }
      return value;
    },
  },
};
</script>
<style lang="scss" scoped>
.link{
    padding-bottom: 40px;
}
ul {
  width: 100%;
  li {
    width: 98%;
    margin: 0 auto;
    background: #fff;
    margin-top: 20px;
    border-radius: 20px;
    height: 132px;
    display: flex;
    justify-content: center;
  }
  .small-appicon {
    height: 130px;
    width: 110px;
    position: relative;
    img {
      width: 110px;
      height: 110px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .text {
    height: 100%;
    margin-left: 16px;
    p {
      margin: 0;
      font-size: 36px;
      margin-top: 14%;
      span {
        margin: 0;
        color: #999;
        font-size: 26px;
      }
    }
  }
}
</style>