<template>
  <div class="caip">
    <a :href="kefu_address" v-if="kefu_address" target="_blank">
      <img v-lazy="logoNameImg" alt="" />
    </a>
    <img v-lazy="logoNameImg" alt="" v-else />
  </div>
</template>

<script>
import { get } from "lodash-es";
export default {
  name: "logoNameImg",
  props: {
    logoNameImg:{
      require: true
    }
  },
  computed: {
        kefu_address() {
            return get(this.$store.state, "webUrls.official_kefu_url", "");
        },
    },
};
</script>

<style lang="scss" scoped>
.caip {
  width: 100%;
  img {
    width: 100%;
  }
}
</style>