<template>
  <div class="banner">
    <img v-lazy="bannerImg" alt="" />
  </div>
</template>

<script>
export default {
  name: "banner",
  props: {
    bannerImg:{
      require: true
    }
  }
};
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  img {
    width: 100%;
  }
}
</style>