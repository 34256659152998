<template>
  <div class="xianlu">
    <div class="title">
      <div class="txt">
        线路中心
      </div>
      <div class="lab">
        温馨提示：ms越小，速度越快
      </div>
    </div>
    <ul>
      <li v-for="(item, index) in list" :key="index">
        <div class="linesLeft">
          <span v-html="random(1, 100) + 'ms'"></span>
          <p>{{ item }}</p>
        </div>
        <button class="enter" @click="open(item)"></button>
      </li>
    </ul>
  </div>
</template>

<script>
import getWebUrlheartbeatsMixins from "@c/mixins/getWebUrlheartbeats.mixins";
export default {
  mixins: [getWebUrlheartbeatsMixins],
  name: "xianlu",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.xianlu {
  width: 100%;
  box-sizing: border-box;
  font-size: 25px;
  margin-bottom: 60px;
  padding: 0px 40px;

  .title {
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .txt {
      height: 90px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 32px;
      color: #000;
    }

    .lab {
      height: 90px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      color: #d29d6b;
    }
  }

  img {
    width: 100%;
    margin-top: 50px;
    margin-bottom: 25px;
  }

  ul {
    width: 716px;
    margin: 0 auto;

    li {
      padding: 20px 20px;
      overflow: hidden;
      display: flex;
      justify-content: space-between;

      .linesLeft {
        width: 486px;
        height: 62px;
        border-radius: 31px 0 0 31px;
        text-align: center;
        line-height: 62px;
        color: #fff;
        overflow: hidden;
        display: flex;

        span {
          float: left;
          display: block;
          width: 128px;
          text-align: center;
          color: #fff;
          line-height: 60px;
          border-right: 2px solid #3c82ff;
          background-color: #285ae9;
        }

        p {
          width: 378px;
          height: 62px;
          background-color: #fff;
          border: 1px solid #b99d6b;
          border-radius: 0 0.31rem 0.31rem 0;
          text-align: center;
          line-height: 62px;
          font-size: 28px;
          color: #c0a68c;
          font-weight: 700;
          font-weight: 700;
          box-shadow: inset 0 0.1rem 0.2rem rgba(0, 0, 0, .2);
          position: relative;
          padding: 0 6px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .enter {
        background: url('../../../../../assets/base64Img/Enter.png') no-repeat;
        background-size: 200px 64px;
        border: none;
        height: 60px;
        width: 200px;
      }

      .disabledBtn {
        background: #c0c4cc;
      }
    }
  }
}
</style>
