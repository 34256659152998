<template>
  <div class="container">
    <div class="box" @click="refrash()">
      <img :src="$store.getters.getUrl('cp_kefu')" alt="" />
      <p>线路中心</p>
    </div>
    <img :src="$store.getters.getUrl('cp_logo')" alt="" class="logo" />
    <div class="box" @click="openPop()">
      <img :src="$store.getters.getUrl('cp_iphone')" alt="" />
      <p>信任教程</p>
    </div>
    <transition name="fade">
      <TrustTeachPop v-if="TrustTeachPopShow" @closePop='closePop' :imgUrl="teach"></TrustTeachPop>
    </transition>
  </div>
</template>


<script>
import TrustTeachPop from "../../../../landing/components/trustTeachPop.vue"
export default {
  data() {
    return {
      TrustTeachPopShow: false
    };
  },
  components: {
    TrustTeachPop
  },
  computed: {
    //备份方案，教程配置了就读配置，没配置读默认图片
    teach() {
      return (
        this.$store.getters.getUrl("cp_teach")
      );
    },
  },
  methods: {
    refrash() {
      window.location.reload()
    },
    closePop(closePop) {
      this.TrustTeachPopShow = closePop;
      document.body.removeAttribute('style')
    },
    openPop() {
      this.TrustTeachPopShow = true
      document.querySelector('body').setAttribute('style', 'overflow:hidden;')
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 91px;
  background: #db443c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
  p {
    font-size: 26px;
    color: #fff;
    text-decoration: none;
    margin-left: 8px;
  }
  .logo {
    width: 182px;
  }
  .box {
    display: flex;
    align-items: center;
    line-height: 35px;
    cursor: pointer;
    img {
      width: 35px;
    }
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s
  }
  .fade-enter, .fade-leave-to  {
      opacity: 0
  }
}
</style>