<script>
export default {
  computed: {
    url() {
      return (
        this.$store.getters.cdn +
        ((this.$store.state.appConfig.landing_config || {}).configValue || {})
          .project_path +
        "/"
      );
    },
    swiperImg_list() {
      return (
        (this.$store.state.appConfig.landing_config || {}).configValue || {}
      ).swiperImg_list;
    },
  },
};
</script>
