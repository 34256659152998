<template>
  <!-- <TopIconContainer >
    <img v-lazy="$store.getters.getUrl('Gold_popularImg')" />
  </TopIconContainer> -->
  <div class="container">
      <img class="icon" :src="iconUrl" v-if="iconUrl" />
    <img v-lazy="heroList" alt="" />
  </div>
</template>

<script>
export default {
  name: "Heroes",
  props: {
    iconUrl: {
      require: true,
    },
    heroList: {
      require: true,
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.container {
  margin-top: -20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  img {
    width: 100%;
    margin-top: 25px;
  }
}
</style>