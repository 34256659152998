<template>
  <div class="xianlu">
    <div class="titles">
      <img v-lazy="$store.getters.getUrl('ks_xlTitle')" alt="" />
      <img
        class="teach"
        v-lazy="$store.getters.getUrl('ks_teachBtn')"
        alt=""
        @click="openPop()"
      />
    </div>
    <ul>
      <li v-for="(item, index) in list" :key="index">
        <div class="linesLeft">
          <span v-html="random(1, 100) + 'ms'"></span>
          <p>{{ item }}</p>
        </div>
        <button class="enter" @click="open(item)">立即进入</button>
      </li>
    </ul>
    <transition name="fade">
      <TrustTeachPop
        v-if="TrustTeachPopShow"
        @closePop="closePop"
        :imgUrl="teach"
      ></TrustTeachPop>
    </transition>
  </div>
</template>

<script>
import getWebUrlheartbeatsMixins from "@c/mixins/getWebUrlheartbeats.mixins";
import TrustTeachPop from "@page/landing/components/trustTeachPop.vue";
export default {
  mixins: [getWebUrlheartbeatsMixins],
  name: "xianlu",
  data() {
    return {
      TrustTeachPopShow: false,
    };
  },
  components: {
    TrustTeachPop,
  },
  computed: {
    teach() {
      return this.$store.getters.getUrl("ks_teach");
    },
  },
  methods: {
    closePop(closePop) {
      this.TrustTeachPopShow = closePop;
      document.body.removeAttribute("style");
    },
    openPop() {
      this.TrustTeachPopShow = true;
      document.querySelector("body").setAttribute("style", "overflow:hidden;");
    },
  },
};
</script>

<style lang="scss" scoped>
.xianlu {
  width: 100%;
  box-sizing: border-box;
  font-size: 25px;
  .titles {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 716px;
    margin: 0 auto;
    img {
      width: 160px;
      &:first-child {
        width: 120px;
      }
    }
    .teach {
      cursor: pointer;
    }
  }
  ul {
    width: 716px;
    margin: 0 auto;
    li {
      margin-top: 0.2rem;
      overflow: hidden;
      .linesLeft {
        float: left;
        overflow: hidden;
        width: 530px;
        height: 60px;
        border-radius: 9px;
        span {
          float: left;
          display: block;
          width: 128px;
          text-align: center;
          color: #fff;
          line-height: 60px;
          background: #e0b367;
        }
        p {
          float: left;
          display: inline-block;
          padding: 0 10px;
          width: calc(100% - 150px);
          text-align: center;
          line-height: 60px;
          color: #000;
          background: #fff;
          font-size: 25px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .enter {
        float: right;
        text-decoration: none;
        border-radius: 8px;
        background: linear-gradient(to bottom,#d33736, #bb1836);
        color: #ffffff;
        text-align: center;
        line-height: 60px;
        width: 162px;
        height: 60px;
        display: block;
        font-size: 25px;
        border: none;
        cursor: pointer;
      }
      .disabledBtn {
        background: #c0c4cc;
      }
    }
  }
}
</style>
