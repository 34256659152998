<template>
  <div class="xianlu">
    <img :src="$store.getters.getUrl('ob_xlTitle')" alt="" />
    <ul>
      <li v-for="(item, index) in list" :key="index">
        <div class="linesLeft">
          <span v-html="random(1, 100) + 'ms'"></span>
          <p>{{ item }}</p>
        </div>
        <button class="enter" @click="open(item)">立即进入</button>
      </li>
    </ul>
  </div>
</template>

<script>
import getWebUrlheartbeatsMixins from "@c/mixins/getWebUrlheartbeats.mixins";
export default {
  mixins: [getWebUrlheartbeatsMixins],
  name: "xianlu",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.xianlu {
  width: 100%;
  box-sizing: border-box;
  font-size: 25px;
  margin-bottom: 60px;
  img {
    width: 100%;
    margin-top: 50px;
    margin-bottom: 25px;
  }
  ul {
    width: 716px;
    margin: 0 auto;
    li {
      margin-top: 0.2rem;
      overflow: hidden;
      .linesLeft {
        border: 2px solid #3c82ff;
        float: left;
        overflow: hidden;
        width: 530px;
        height: 60px;
        span {
          float: left;
          display: block;
          width: 128px;
          text-align: center;
          color: #000;
          line-height: 60px;
          border-right: 2px solid #3c82ff;
        }
        p {
          float: left;
          display: inline-block;
          padding: 0 10px;
          width: calc(100% - 150px);
          text-align: center;
          line-height: 60px;
          color: #000;
          font-size: 25px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .enter {
        float: right;
        text-decoration: none;
        border-radius: 8px;
        background: #3c82ff;
        color: #ffffff;
        text-align: center;
        line-height: 60px;
        width: 162px;
        height: 60px;
        display: block;
        font-size: 25px;
        border: none;
        cursor: pointer;
      }
      .disabledBtn {
        background: #c0c4cc;
      }
    }
  }
}
</style>
