<template>
    <div class="btns">
        <a :href="app_address_fixed" class="btn" target="_blank">
            <img v-lazy="$store.getters.getUrl('yb_iphoneDown')" alt="" />
        </a>
        <a :href="app_address_fixed" class="btn" target="_blank">
            <img v-lazy="$store.getters.getUrl('yb_andDown')" alt="" />
        </a>
    </div>
</template>

<script>
import { get } from "lodash-es";
export default {
  name: "downBtn",
  data() {
    return {};
  },
  computed: {
    app_address_fixed() {
      return get(this.$store.state, "webUrls.app_address_fixed", "");
    },
  },
};
</script>

<style lang="scss" scoped>
.btns {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 25px;
  .btn {
    width: 250px;
    animation: myfirst 1s infinite linear;
    &:last-child{
        margin-left: 50px;
    }
    img {
        width: 100%;
    }
  }
}
@keyframes myfirst {
  from {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}
</style>