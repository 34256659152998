<template>
  <div class="pj_content">
    <Header></Header>
    <KindList></KindList>
    <Mian></Mian>
    <LotteryType></LotteryType>
    <Footer></Footer>
    <Bottom :bottomImg="$store.getters.getUrl('pj01_footer_fixed')"></Bottom>
  </div>
</template>

<script>
import Header from "@page/landing/template/pj01/components/header.vue";
import KindList from "@page/landing/template/pj01/components/KindList.vue";
import Mian from "@page/landing/template/pj01/components/mian.vue";
import LotteryType from "@page/landing/template/pj01/components/lotteryType.vue";
import Footer from "@page/landing/template/pj01/components/footer.vue";
import Bottom from "@page/landing/template/pj01/components/bottom.vue";
export default {
  components: {
    Header,
    KindList,
    Mian,
    LotteryType,
    Footer,
    Bottom
  },
};
</script>

<style lang="scss" scoped>
.pj_content {
  background: url("../../../../assets/base64Img/pj01.jpg") center center;
  background-size: 100% 100%;
}
</style>
